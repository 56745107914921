import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmEmailComponent } from './admin/authentication/confirm-email/confirm-email.component';
import { LoginComponent } from './admin/authentication/login/login.component';
import { LogoutComponent } from './admin/authentication/logout/logout.component';
import { MaintenanceComponent } from './admin/authentication/maintenance/maintenance.component';
import { ProfileComponent } from './admin/authentication/profile/profile.component';
import { ResetPasswordComponent } from './admin/authentication/reset-password/reset-password.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { adminAuthGuard } from './core/guards/admin-auth.guard';
import { ForgotPasswordComponent } from './shared/components/forgot-password/forgot-password.component';
import { OtpVerificationComponent } from './shared/components/otp-verification/otp-verification.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: 'admin',
    component: DashboardComponent,
    canActivate: [adminAuthGuard],
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'verify',
    component: OtpVerificationComponent,
  },
  {
    path: 'forgotpassword',
    component: ForgotPasswordComponent,
  },
  {
    path: 'resetpassword',
    component: ResetPasswordComponent,
  },
  {
    path: 'profile',
    component: ProfileComponent,
  },
  {
    path: 'confirmEmail',
    component: ConfirmEmailComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent,
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  { path: '**', pathMatch: 'full', component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      bindToComponentInputs: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

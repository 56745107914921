export const IconList = [
  'add-icon',
  'back-arrow',
  'mail-icon',
  'hide-icon',
  'user-icon',
  'userRole-icon',
  'history-icon',
  'notification-icon',
  'calendar-icon',
  'calendar',
  'temp-icon',
  'HJHours-icon',
  'billable-icon',
  'close-icon',
  'downarrow-black',
  'edit-icon',
  'delete-icon',
  'search-icon',
  'brifecase-tick',
  'phase',
  'book-icon',
  'loader-animation',
  'client-icon',
  'service-plan-icon',
  'pound-currency-icon',
  'loading-time-icon',
  'add-user',
  'rounded',
  'time-locked',
  'matter-icon',
  'close-red',
  'search-icon-big',
  'play-icon',
  'stop-icon',
  'lawyersUser',
  'User-icon-small',
  'category-icon',
  'clock-icon',
  'clock-started-icon',
  'pdf-download-icon',
  'groupby-icon',
  'filter-edit',
  'time-play-icon',
  'payment-pound-icon',
  'modal-close-icon',
  'play-icon-green',
  'document-icon',
  'rate-icon',
  'resend-invoice-icon',
  'invoice-sent-icon',
  'download-icon',
  'view-icon',
  'edit-icon-white',
  'play-white-ico',
  'edit-timer-icon',
  'clock-white-ico',
  'timer-stop-icon',
  'mail-icon-blue',
  'call-icon',
  'job-icon',
  'upload-icon',
  'close-icon-grey',
  'pdf-icon',
  'brifecase-timer',
  'feedback-list',
  'navigate-next',
  'navigate-before',
  'call',
  'book',
  'frame',
  'hashtag',
  'information',
  'refresh',
  'hjs-logo-white',
  'home-icon'
];

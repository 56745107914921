import { Component, HostListener, OnDestroy, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SvgIconRegistryService } from 'angular-svg-icon';
import { Subject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as Icons from '../assets/icons/icon.constants';
import { environment } from '../environments/environment';
import { AuthService } from './shared/services';
import { AppInsightsService } from './shared/services/app-insights.service';
import { HubConnectionService } from './shared/services/hub-connection.service';
import { OnlineService } from './shared/services/online.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  online: boolean;
  currentUserId: number;
  currentApplicationVersion = environment.appVersion;
  isSystemUnderMaintenance: boolean = false;
  @HostListener('window:popstate', ['$event'])
  onBrowserBackBtnClose(event: Event) {
    this.modalService.dismissAll();
    if (this.router.url.includes('profile')) {
      this.router.navigate(['/admin']);
    } else if (this.router.url.includes('forgotpassword')) {
      this.router.navigate(['/login'], {
        replaceUrl: true,
      });
    } else if (this.router.url.includes('admin')) {
      location.reload();
    }
    if (
      this.router.url.includes('login') ||
      this.router.url.includes('verify') ||
      this.router.url.includes('resetpassword') ||
      this.router.url.includes('forgotpassword')
    ) {
      history.pushState(null, '', location.href);
    }
    event.preventDefault();
  }

  title = 'Harper James';
  @HostListener('window:beforeunload', ['$event'])
  unloadHandler() {
    if (
      (document.visibilityState == 'hidden' && localStorage.getItem('isRM') === 'false') ||
      !localStorage.getItem('isRM')
    ) {
      this._authService.logout();
    }
  }

  private onDestroy$: Subject<void> = new Subject<void>();
  private appInsightsService = inject(AppInsightsService);

  public constructor(
    private svgIconRegistryService: SvgIconRegistryService,
    private router: Router,
    private _onlineService: OnlineService,
    private modalService: NgbModal,
    private _authService: AuthService,
    private _hubConnectionService: HubConnectionService,
  ) {
    this.subscribeSiteUnderMaintenance();

    iconNames.forEach((iconName: IconName) => {
      this.svgIconRegistryService.loadSvg(`${iconDirectory}/${iconName}.svg`, iconName);
    });

    if (!window.navigator.onLine) {
      this._onlineService.setOnline(false);
      this.online = false;
    } else {
      this._onlineService.setOnline(true);
      this.online = true;
    }

    fromEvent(window, 'online').subscribe(() => {
      this._onlineService.setOnline(true);
      this.online = true;
      location.reload();
    });

    fromEvent(window, 'offline').subscribe(() => {
      this._onlineService.setOnline(false);
      this.online = false;
    });
  }

  ngOnInit() {
    this._hubConnectionService.setupHubConnection();
    this.appInsightsService.trackEvent('App Loaded', { screensize: `${window.innerWidth}x${window.innerHeight}` });
  }

  subscribeSiteUnderMaintenance() {
    this._authService.siteUnderMaintenanceObservable
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((isUnderMaintenance: boolean) => {
        this.isSystemUnderMaintenance = isUnderMaintenance;
      });
  }

  ngOnDestroy(): void {
    if (localStorage.getItem('isRM') === 'false' || !localStorage.getItem('isRM')) {
      localStorage.clear();
    }
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }
}

const iconDirectory = 'assets/icons';
const iconNames = Icons.IconList;
type IconName = (typeof iconNames)[number];
